const React = require("react");

exports.onRenderBody = ({ setPostBodyComponents }) => {
  setPostBodyComponents([
    <noscript
      key="gtm-noscript"
      dangerouslySetInnerHTML={{
        __html: `
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KHPSNKHZ"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `,
      }}
    />,
  ]);
};
